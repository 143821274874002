import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImgText from "../components/imgText"
import TextImg from "../components/textImg"

import Cta from "../components/cta"
import Seo from "../components/Seo/seo"
import GalleryJson from "../components/Seo/galleryJson"
export default function Gallery({ data }) {
  return (
    <Layout>
      <Seo
        title="Costa Rica Tour Gallery"
        description="Get a glimpse the natural wonders and cultural heritage awaiting you during the Costa Rica tour."
        image={data.sunsetBoat}
      />
      <GalleryJson />
      <h1 className="display-1 text-center my-5 lstitle text-uppercase">
        Pictures we took in Costa Rica
      </h1>
      <Carousel />
      <div className="mt-5" />
      <h2 className="fw-lighter display-5 text-center mb-5">
        Discover the BEAUTY of Costa Rica.
      </h2>

      <ImgText
        title="DISCOVER Rare and Endangered Species"
        text="In one of the many fantastic National Parks and Reserves."
        link=""
        btn=""
        img={data.sloth}
        imgAlt="Sloth in Costa Rica"
      />

      <TextImg
        title="RUN on endless beaches"
        text="Breathe in the tranquility of long, lonely beaches, get fit or find inner peace."
        link=""
        btn=""
        img={data.beachRun}
        imgAlt="Beach Running Costa Rica"
      />
      <ImgText
        title="CLIMB Active Volcanos"
        text="Make it to the top and enjoy the view over active volcano craters, wonder about the moonlike structures or fauna and flora in their proximity."
        link=""
        btn=""
        img={data.volcano}
        imgAlt="View from a Volcano in Costa Rica"
      />

      <TextImg
        title="ANCIENT - not Old"
        text="Reveal indigen history or get to know the colonial heritage."
        link=""
        btn=""
        img={data.artefacts}
        imgAlt="Artefact Costa Rica"
      />
      <ImgText
        title="BEAUTIFUL Sunsets"
        text="Costa Rica has them. Plenty. A Soothing experience after surfing."
        link=""
        btn=""
        img={data.sunsetBoat}
        imgAlt="Sunset with Boat and Surfer in Costa Rica"
      />

      <TextImg
        title="MEET a COLIBRI"
        text="Have you ever been nose to beak with a colibri? It's possible in Costa Rica."
        link=""
        btn=""
        img={data.colibri}
        imgAlt="Colibri in Costa Rica"
      />
      <ImgText
        title="WILD monkeys are waiting"
        text="Costa Rica features many species of monkeys - still living wild and free in the jungle."
        link=""
        btn=""
        img={data.monkeys}
        imgAlt="Wild monkeys in Costa Rica"
      />
      <TextImg
        title="Did we say SUNSETS?"
        text="We mean it. Enjoy them with a fresh fruit drink you find everywhere!"
        link=""
        btn=""
        img={data.sunsetRed}
        imgAlt="Red Sunset at a Costa Rican beach"
      />

      <div className="mt-5 mb-5"></div>
      <Cta
        title="Make your own Gallery"
        subtitle="Join us in Costa Rica and take stunning photographs for your own albums!"
        link="/booking"
        btn="Experience Costa Rica"
        bgcolor="#a70c36"
        txtcolor="text-white"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    cooking: file(name: { eq: "cooking-creole-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    volcano: file(name: { eq: "volcano-view-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    artefacts: file(name: { eq: "artefacts-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sloth: file(name: { eq: "sloth-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    monkeys: file(name: { eq: "monkeys-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sunsetBoat: file(name: { eq: "sunset-boat-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    beachRun: file(name: { eq: "beach-running-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    colibri: file(name: { eq: "colibri-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    sunsetRed: file(name: { eq: "sunset-red-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
