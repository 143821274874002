import React from "react"
import JsonLd from "./jsonld"
import { useStaticQuery, graphql } from "gatsby"

export default function GalleryJson() {
  const { site } = useStaticQuery(query)
  const { legalName, siteUrl } = site.siteMetadata
  return (
    <JsonLd>
      {{
        "@context": "http://schema.org",
        "@type": ["WebPage", "MediaGallery", "ImageGallery"],
        name: "Costa Rica Tour Gallery",
        description:
          "Photo Gallery from the Costa Rica Tours organized by LatinSalsa.de",
        publisher: {
          "@type": "Organization",
          name: `${legalName}`,
          url: `${siteUrl}`,
        },
      }}
    </JsonLd>
  )
}

const query = graphql`
  query GalleryJson {
    site {
      siteMetadata {
        siteUrl
        legalName
      }
    }
  }
`
