import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Carousel from "react-bootstrap/Carousel"
import { useStaticQuery, graphql } from "gatsby"

const CarouselComponent = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "carousel" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <Carousel>
        {data.allFile.edges.map(file => {
          return (
            <Carousel.Item
              className="justify-content"
              key={file.node.id}
              style={{ minHeight: "45vH" }}
            >
              <GatsbyImage
                image={file.node.childImageSharp.gatsbyImageData}
                alt={file.node.name}
                className="w-100"
                style={{ minHeight: "45vH" }}
              />
            </Carousel.Item>
          )
        })}
      </Carousel>
    </div>
  )
}
export default CarouselComponent
